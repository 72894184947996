module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"verbose":true,"url":"https://wp-locations-ireland.axon.host/graphql","schema":{"typePrefix":"Wp","timeout":300000,"requestConcurrency":50,"queryDepth":15,"circularQueryLimit":5,"perPage":100,"previewRequestConcurrency":5},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"type":{"Post":{"limit":5000},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Locations Guild of Ireland","shortName":"Locations Guild of Ireland","description":"Locations Guild of Ireland","startUrl":"/","lang":"en","display":"standalone","themeColor":"#ffffff","backgroundColor":"#ffffff","icon":"src/favicon/locations-ireland/favicon.png","icons":[{"src":"src/favicon/locations-ireland/favicon-16x16.png","type":"image/png","sizes":"16x16"},{"src":"src/favicon/locations-ireland/favicon-32x32.png","type":"image/png","sizes":"32x32"},{"src":"src/favicon/locations-ireland/favicon.png","type":"image/png","sizes":"512x512"}],"short_name":"Locations Guild of Ireland","start_url":"/","theme_color":"#ffffff","background_color":"#ffffff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ba2f6da39a9deba34c15e0481e37ea0a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
