import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { WpEdges, WpProduct } from '../../types/wordpress.types';

const useAllWpProduct = () => {

  const { allProduct } = useStaticQuery(graphql`
  {
    allProduct: allWpProduct(
      filter: {status: {eq: "publish"}}
      sort: {date: ASC}
    ) {
      edges {
        node {
          ...ProductWithCategoriesAndProductFieldsFragment
        }
      }
    }
  }
  `);

  const productsQueried: WpProduct[] = (allProduct.edges as WpEdges<WpProduct>[]).map(p => p.node);
  return productsQueried;
}

export default useAllWpProduct;