// this file name starts with @ because it will be automatically listed before other modules in the same when imported.
// it is used in gatsby-browser.tsx configuration file.

import { configure } from "mobx";

configure({
  // enforceActions: 'never',
  useProxies: 'ifavailable',
})

export default function configureMobx () {};