import { isBrowser } from "../env";
import tick from "./waiters.utils";

export const addRootClass = (value: string) => isBrowser ? (value.includes(' ') ? value.split(' ').forEach(c => document.documentElement.classList.add(c)) : document.documentElement.classList.add(value)) : null;
export const removeRootClass = (value: string) => isBrowser ? (value.includes(' ') ? value.split(' ').forEach(c => document.documentElement.classList.remove(c)) : document.documentElement.classList.remove(value)) : null;
export const hasRootClass = (value: string) => isBrowser ? (value.includes(' ') ? value.split(' ').some(c => document.documentElement.classList.contains(c)) : document.documentElement.classList.contains(value)) : null;

export function makeElement<K extends keyof (HTMLElementTagNameMap & SVGElementTagNameMap)>(
  tagName: K,
  className: string = '',
  ...content: (string | HTMLElement | null | undefined)[]
) {
  const el = document.createElement(tagName);
  className.split(' ').forEach(c => c && el.classList.add(c));
  content?.forEach(c => {
    if (c === null || c === undefined) return;
    if (typeof c === 'string') {
      const temp = document.createElement('div');
      temp.innerHTML = c;
      el.append(...Array.from(temp.childNodes));
    } else {
      el.append(c);
    }
  })
  return el;
}

export const loadScript = (src: string) => new Promise<true>(
  (resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = (e) => reject(e)
    document.head.appendChild(script);
  }
)

export const asyncPrependToContainer = async (
  elementGetter: () => HTMLElement,
  containerGetter: () => HTMLElement,
  delay?: number
) => {
  await tick(delay);
  containerGetter().prepend(elementGetter());
}

export const getScrollY = () => window.scrollY ?? window.pageYOffset ?? document.documentElement.scrollTop;