import { observable } from "mobx";
import { isBuildTime } from "../env";
import { ObservableRef } from "../hooks/useObservableRef.hook";
import { isIE } from "./browsers.utils";
import { getScrollY } from "./dom.utils";
import { NoOp } from "./functions.utils";
import { highPerf } from "./performance.utils";
import { observeVisibility } from "./visibilityObserver.util";

type ParallaxConfig = {
  el: HTMLElement | SVGElement,
  depth: number,
  direction: 'x' | 'y'
  customFunction?: () => void,
  disabled?: boolean,
}

const elementConfigSet = new Set<ParallaxConfig>();

export const initParallax = () => {
  if (isBuildTime) return;
  if (isIE) return;
  if (!highPerf) return;
  const handler = () => {
    elementConfigSet.forEach(c => {
      if (c.disabled) return;
      if (c.customFunction) {
        c.customFunction();
        return;
      }
      if (
        !c.el
        // || c.el.classList.contains('invisible')
      ) return;
      const offset = getScrollY() * c.depth / window.innerHeight * 100;
      if (c.direction === 'y') {
        c.el.style.transform = `translateY(${offset}%)`;
      } else if (c.direction === 'x') {
        c.el.style.transform = `translateX(${offset}%)`;
      }
    })
  }
  window.addEventListener('scroll', handler);
  handler();
  return () => {
    window.removeEventListener('scroll', handler);
  }
}

export const registerParallaxEffect = (ref: ObservableRef<HTMLElement | SVGElement> | HTMLElement | SVGElement, options: {
  id: string,
  depth?: number,
  direction?: 'x' | 'y',
  customFunction?: () => void
  shouldDisable?: () => boolean,
}) => {
  if (isBuildTime) return NoOp;
  if (!highPerf || isIE) return NoOp;
  if (ref instanceof Element || ref.current) {
    const el = ref instanceof Element ? ref : ref.current;
    if (!el) return NoOp;
    const c = observable({
      el,
      get depth() { return options?.depth ?? .5 },
      get direction() { return options?.direction ?? 'y' },
      get customFunction() { return options?.customFunction },
      get disabled() { return options?.shouldDisable?.() ?? false },
    });
    elementConfigSet.add(c);
    observeVisibility(ref, options);
    return () => {
      elementConfigSet.delete(c);
    }
  }
  return NoOp;
}