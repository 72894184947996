import { action, reaction } from "mobx";
import { isBuildTime } from "../env";
import { makeDisposerController } from "../utils/disposer.utils";
import { useOnMount } from "./lifecycle.hooks";

export const getStorageObject = (
  use?: 'localStorage' | 'sessionStorage',
) => {
  return use === 'sessionStorage' ? sessionStorage : localStorage;
}

export const saveToStorage = (
  key: string,
  value: any,
  use?: 'localStorage' | 'sessionStorage',
) => {
  if (isBuildTime) return;
  const storage = getStorageObject(use);
  if (!storage) return;
  storage.setItem(key, JSON.stringify(value));
}

export const readFromStorage = (
  key: string,
  use?: 'localStorage' | 'sessionStorage',
) => {
  if (isBuildTime) return;
  const storage = getStorageObject(use);
  if (!storage) return;
  const value = storage.getItem(key);
  if (value) return JSON.parse(value);
  return;
}

export const useSyncObservableStateToStorage = <T = any>(
  key: string,
  getter: () => T,
  setter: (v: T) => void,
) => {
  useOnMount(() => {
    if (isBuildTime) return;
    const d = makeDisposerController();
    action(setter)(readFromStorage(key));
    d.add(
      reaction(
        getter,
        value => saveToStorage(key, value),
        { fireImmediately: true }
      )
    )
    return d.disposer;
  })
}