// import * as Sentry from '@sentry/react';
import { isProduction } from '../env';

// export const shouldStartSentry = isBrowser && process.env.NODE_ENV === 'production';
export const shouldStartSentry = false;

export const log = (message?: any, ...optionalParams: any[]) => {
  if (isProduction) return;
  console.log(message, ...optionalParams);
}
export const alwaysLog = (message?: any, ...optionalParams: any[]) => {
  console.log(message, ...optionalParams);
}

export const warn = (message?: any, ...optionalParams: any[]) => {
  if (isProduction) return;
  console.warn(message, ...optionalParams);
}

export const group = (...label: any[]) => {
  if (isProduction) return;
  console.group(...label);
}
export const groupEnd = () => {
  if (isProduction) return;
  console.groupEnd();
}

export function reportError(e: string | Error) {
  console.error(e);
  const error = typeof e === 'string' ? new Error(e) : e;
  // if (shouldStartSentry) Sentry.captureException(error);
}

export function runAndIgnoreError(fn?: Function) {
  try {
    fn && fn()
  } catch(e) {
    console.warn('An error was ignored:', e);
  }
} 
