import { isBrowser } from "../env";
import { addRootClass } from "./dom.utils";

const ua = isBrowser ? navigator.userAgent : '';
const platform = isBrowser ? navigator.platform ?? '' : '';

export const isIE = ua.indexOf("MSIE ") >= 0 || !!ua.match(/Trident.*rv:11\./);
export const isFirefox = (ua.indexOf('Firefox') >= 0);
export const isWebKit = (ua.indexOf('AppleWebKit') >= 0);
export const isChrome = (ua.indexOf('Chrome') >= 0);
export const isSafari = (!isChrome && (ua.indexOf('Safari') >= 0));
export const isWebkitWebView = (!isChrome && !isSafari && isWebKit);
export const isIPad = (ua.indexOf('iPad') >= 0 || (isSafari && navigator.maxTouchPoints > 0));
export const isIPhone = (ua.indexOf('iPhone') >= 0 || (isSafari && navigator.maxTouchPoints > 0));
export const isIOS = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(platform);
export const isAppleBrowser = isIOS || isSafari;
export const isEdgeLegacyWebView = (ua.indexOf('Edge/') >= 0) && (ua.indexOf('WebView/') >= 0);
export const isElectron = (ua.indexOf('Electron/') >= 0);
export const isAndroid = (ua.indexOf('Android') >= 0);
export const isStandalone = isBrowser ? (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) : false;
export const isChromiumEdge = isChrome && /\sEdg\//.test(ua);
export const isEdgeLegacy = !isChromiumEdge && ua.includes("Edge");

export function detectBrowsers () {
  if (isBrowser) {
    if (isIE) addRootClass('ie');
    if (isFirefox) addRootClass('firefox');
    if (isWebKit) addRootClass('webkit');
    if (isChrome) addRootClass('chrome');
    if (isSafari) addRootClass('safari');
    if (isIPad) addRootClass('iPad');
    if (isIPhone) addRootClass('iPhone');
    if (isIOS) addRootClass('iOS');
    if (isAppleBrowser) addRootClass('apple');
    if (isElectron) addRootClass('electron');
    if (isAndroid) addRootClass('android');
    if (isChromiumEdge) addRootClass('edge');
    if (isEdgeLegacy) addRootClass('edge-legacy');
  }
}